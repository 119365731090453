export default {
  hosts: {
    sdk_host: 'https://sdk-uk.shortlyst.com/',
    brandstore_host: 'https://brand-uk.shortlyst.com/',
    legacy_brandstore_host: 'https://brands-uk.shortlyst.com/',
    needs_user_consent: true,
    root_domain: 'shortlyst.com',
    default_consent: false,
    app_url: 'https://ncc-uk.shortlyst.com/v2/',
    prd_sdk_host: 'https://products.shortlyst.com/uk/'
  },
  isGdprRegion: true
}
